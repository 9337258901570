import React from 'react';
import styled from 'styled-components';
import SEO from 'components/seo';
import { Section, Container } from 'components/misc';

const StyledH2 = styled.h2`
  font-size: 1.5rem;
  margin: 20px 0;

  &:first-of-type {
    margin-top: 0;
    margin-bottom: 40px;
  }
`;

const OrderedListItem = styled.li`
  list-style: decimal;
`;

const PrivacyPolicy = ()=> {
  return (
    <>
      <SEO title="Polityka prywatności" />
      <Section topSection>
        <Container>
          <StyledH2>Polityka prywatności</StyledH2>
          <StyledH2>Administrator danych</StyledH2>
          <p>Grupa Ekoenergetyka S.A. z siedzibą w Warszawie (02-676) przy ulicy Postępu 14.</p>
          <StyledH2>Pliki cookies</StyledH2>
          <ol>
            <OrderedListItem>
            Pliki cookies stanowią dane informatyczne, w szczególności pliki tekstowe, które są pobierane i przechowywane w urządzeniu końcowym użytkownika strony internetowej przez czas określony w parametrach pliku lub do czasu ich usunięcia przez użytkownika. W plikach cookies przechowywane są informacje, które są często niezbędne do prawidłowego działania witryny internetowej, a zawierają w szczególności nazwę domeny serwisu internetowego, z którego pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalną nazwę.
            </OrderedListItem>
            <OrderedListItem>
            Wykorzystywanie plików cookies jest konieczne do prawidłowego świadczenia usług na stronie internetowej, a Administrator wykorzystuje pliki cookies w celu lepszego poznania sposobu interakcji użytkownika w zakresie zawartości strony.
            </OrderedListItem>
            <OrderedListItem>
            Użytkownik ma prawo zadecydowania w zakresie dostępności plików cookies dla swojego komputera poprzez ich uprzedni wybór w oknie przeglądarki. Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach przeglądarki internetowej.
            </OrderedListItem>
          </ol>
          <StyledH2>Postanowienia końcowe</StyledH2>
          <ol>
            <OrderedListItem>
            Administrator stosuje środki techniczne i organizacyjne zapewniające ochronę przetwarzanych danych osobowych, a w szczególności zabezpiecza dane przed ich udostępnieniem osobom nieupoważnionym, przetwarzaniem z naruszeniem obowiązujących przepisów oraz utratą, uszkodzeniem lub zniszczeniem.
            </OrderedListItem>
            <OrderedListItem>
            Administrator udostępnia odpowiednie środki techniczne zapobiegające pozyskiwaniu i modyfikowaniu danych osobowych przez osoby nieuprawnione.
            </OrderedListItem>
            <OrderedListItem>
            W sprawach nieuregulowanych niniejszą Polityką prywatności stosuje się odpowiednio przepisy RODO oraz inne właściwe przepisy prawa polskiego.
            </OrderedListItem>
          </ol>
        </Container>
      </Section>
    </>
  );
};

export default PrivacyPolicy;
